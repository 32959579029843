/* src/AboutUs.css */
.about-us-container {
    padding: 40px;
    max-width: 1000px;
    color:white;
    margin: 0 auto;
    font-family: 'Arial', sans-serif;
  }
  
  .about-us-header {
    color:white;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .about-us-header h1 {
    color:white;
    font-size: 2.5em;
    margin-bottom: 10px;
  }
  
  .about-us-header p {
    font-size: 1.1em;
    color:white;
  }
  
  .about-us-story, .about-us-values, .about-us-team {
    margin-bottom: 40px;
    color:white;
  }
  
  .about-us-story h2, .about-us-values h2, .about-us-team h2 {
    font-size: 2em;
    color:white;
    margin-bottom: 20px;
  }
  
  .about-us-story p, .about-us-values ul, .about-us-team p {
    font-size: 1.1em;
    color:white;
  }
  
  .about-us-values ul {
    list-style-type: disc;
    color:white;
    padding-left: 20px;
  }
  
  .about-us-values ul li {
    margin-bottom: 10px;
    color:white;
  }
  
  .about-us-team {
    display: flex;
    flex-direction: column;
    color:white;
    align-items: center;
  }
  
  .team-member {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
    padding: 10px;
    background: #e4bf92;
    max-width: 800px;
    width: 100%;
  }
  
  .team-member-img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
  }
  
  .team-member-info {
    flex: 1;
  }
  
  .team-member-info h3 {
    margin: 0;
    font-size: 1.5em;
    color: #333;
  }
  
  .team-member-info p {
    margin: 5px 0;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .about-us-container {
      padding: 20px;
    }
  
    .about-us-header h1 {
      font-size: 2em;
    }
  
    .about-us-header p {
      font-size: 1em;
    }
  
    .about-us-story h2, .about-us-values h2, .about-us-team h2 {
      font-size: 1.6em;
    }
  
    .about-us-story p, .about-us-values ul, .about-us-team p {
      font-size: 1em;
    }
  
    .about-us-values ul {
      padding-left: 15px;
    }
  
    .team-member {
      flex-direction: column;
      align-items: center;
      padding: 15px;
    }
  
    .team-member-img {
      width: 100px;
      height: 100px;
      margin-right: 0;
      margin-bottom: 10px;
    }
  
    .team-member-info h3 {
      font-size: 1.3em;
    }
  }
  
  @media (max-width: 480px) {
    .about-us-header h1 {
      font-size: 1.5em;
    }
  
    .about-us-header p {
      font-size: 0.9em;
    }
  
    .about-us-story h2, .about-us-values h2, .about-us-team h2 {
      font-size: 1.4em;
    }
  
    .about-us-story p, .about-us-values ul, .about-us-team p {
      font-size: 0.9em;
    }
  
    .about-us-values ul {
      padding-left: 10px;
    }
  
    .team-member {
      padding: 10px;
    }
  
    .team-member-img {
      width: 80px;
      height: 80px;
    }
  
    .team-member-info h3 {
      font-size: 1.1em;
    }
  }
  
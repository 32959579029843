/* feedback.css */

body {
  font-family: Arial, sans-serif;
}

.button {
  padding: 10px 20px;
  background-color: rgb(182, 51, 51);;
  color: white;
  border: none;
  border-radius: 5px;
  height: 45px;
  cursor: pointer;
  margin: 5px;
}

.button:hover {
  background-color: #5a1e0e;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
  width: 400px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column; /* Ensure children are stacked vertically */
  align-items: center; /* Center align children */
}

textarea {
  width: 100%; /* Full width */
  height: 100px;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box; /* Include padding and border in element's total width */
}

input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box; /* Include padding and border in element's total width */
}

.success-alert {
  background-color: #dff0d8;
  color: #3c763d;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #d6e9c6;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.success-alert .icon {
  margin-right: 5px;
}

/* Responsive styles */
@media (max-width: 600px) {
  .popup-content {
    width: 0%;
    padding: 15px;
  }

  .button {
    width: auto;
    height: 35px;
    /* margin: 5px 0; */
  }
}

/* Base Styles */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

/* Header Styles */
.header {
    background-color: var(--primary-color);
    opacity: 100%;
    display: flex;
    border-style: solid;
    border-width: 1px;
    border-color: #444444;
    justify-content: space-between;
    align-items: center;
    height: 9.5vh;
    padding: 0 20px;

    color: white;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

/* Search Container Styles */
.search-container {
    margin-right: 10px;
    width: 100%;
    max-width: 600px;
    display: flex;
    align-items: center;
    background-color: white;
    border-style: solid;
    border-width: 1px;
    border-color: #444444;
    padding: 5px 10px;
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.search-container input {
    padding: 10px 15px;
    width: 100%;
    font-size: 16px;
    border: none;
    outline: none;
    border-radius: 25px;
}

.search-container input::placeholder {
    color: #aaa;
}

/* Logos and Button Styles */
.logos {
    color: rgb(135, 35, 35);
    font-size: 2.5rem; /* Responsive font size */
}

.header-buttons {
    display: flex;
    align-items: center;
}

#login {
    background-color: rgb(182, 51, 51);
}

.header-buttons button {
    margin-right: 1vw;
    padding: 15px 25px;
    font-size: 1rem; /* Responsive font size */
    cursor: pointer;
    border: 1px solid rgb(68, 68, 68);
    background-color: #191919;
    color: white;
    border-radius: 15px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .header {
        flex-direction: column;
        height: auto;
        padding: 10px;
    }

    .logos {
        margin-bottom: 10px;
    }

    .header-buttons {
        width: 100%;
        justify-content: space-between;
        margin-top: 10px;
    }

    .header-buttons button {
        flex: 1;
        margin: 5px;
        font-size: 0.9rem;
    }

    .search-container {
        width: 100%;
        margin-top: 10px;
    }
}

@media (max-width: 480px) {
    .logos {
        font-size: 2rem;
    }

    .header-buttons button {
        font-size: 0.8rem;
        padding: 8px 10px;
    }

    .search-container {
        width: 100%;
        margin-top: 10px;
    }
}

.navbar-toggle {
    display: none;
    flex-direction: column;
    cursor: pointer;
}
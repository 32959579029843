/* global.css */
:root {
  --primary-color: #cdd7fb;
  --secondary-color: #2ecc71;
  --accent-color: #e74c3c;
  --background-color: white;
  --text-color: #2c3e50;
}
body{
  background-color: black;
  font-family: 'Poppins', sans-serif;
  color:white;
}
body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2000px;
  background: 
    linear-gradient(135deg, rgba(103, 74, 74, 0.6) 0%, rgba(76, 54, 54, 0.4) 50%), /* Red gradient */
    linear-gradient(-135deg, rgba(103, 103, 118, 0.6) 0%, rgba(73, 73, 73, 0.4) 50%); /* Blue gradient */
  pointer-events: none;
  z-index: -1; /* Ensure it stays behind other content */
}

/* Additional flare effect with animation */
@keyframes flare {
  0% {
    transform: scale(1);
    opacity: 0.6;
  }
  50% {
    transform: scale(1.1); /* Slightly adjusted scale */
    opacity: 0.75; /* Slightly adjusted opacity */
  }
  100% {
    transform: scale(1);
    opacity: 0.6;
  }
}

.body-flare {
  animation: flare 3s infinite;
}

/* Responsive Styles */
@media (max-width: 768px) {
  body::before {
    background: 
    linear-gradient(135deg, rgba(103, 74, 74, 0.6) 0%, rgba(76, 54, 54, 0.4) 50%), /* Red gradient */
    linear-gradient(-135deg, rgba(103, 103, 118, 0.6) 0%, rgba(73, 73, 73, 0.4) 50%); /* Blue gradient */
  }
}

@media (max-width: 480px) {
  body::before {
    background: 
    linear-gradient(135deg, rgba(103, 74, 74, 0.6) 0%, rgba(76, 54, 54, 0.4) 50%), /* Red gradient */
    linear-gradient(-135deg, rgba(103, 103, 118, 0.6) 0%, rgba(73, 73, 73, 0.4) 50%); /* Blue gradient */
  }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css');

*{
  color: black;
}
.footer{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background-color: red; */
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  list-style: none;
}
.footer{
  font-weight: 500;
  color:whitesmoke;
  /* background-color: rgba(204, 18, 18, 0.42); */
}
.footer {
  /* position: fixed; */
  bottom: 0;
  width: 100%;
  padding: 40px 0;
  background-color: rgba(73, 66, 66, 0.3);  
  border-top: 1px solid rgb(139, 139, 139);
}
.footer .social {
  text-align: center;
  padding-bottom: 25px;
  color:white;
}
.footer .social a {
  font-size: 24px;
  color: white;
  border: 2px solid rgb(79, 79, 79);
  width: 40px;
  height: 40px;
  line-height: 38px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  margin: 0 8px;
  opacity: 0.7;
  transition: 0.3s ease;
}
.footer .social a:hover {
  transform: scale(1.2) translateY(-10px);
  opacity: 1;
  color: rgb(255, 78, 78);
  border: 2px solid rgb(255, 78, 78);
}
.footer ul {
  margin-top: 0;
  padding: 0;
  font-size: 18px;
  line-height: 1.8;
  color:whitesmoke;
  margin-bottom: 0;
  text-align: center;
}
.footer ul li a {
  color: var(--font-heading-color);
  color:whitesmoke;
  border-bottom: 3px solid transparent;
  transition: 0.3s ease;
}

.footer ul li a:hover {
  border-bottom: 3px solid rgb(255, 78, 78);
}
.footer ul li {
  display: inline-block;
  padding: 0 15px;
}
.footer .copyright {
  margin-top: 15px;
  text-align: center;
  font-size: 12px;
  color: white;
}

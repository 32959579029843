/* src/Services.css */
.services-container {
    padding: 20px;
    max-width: 900px;
    color: white;
    margin: 0 auto;
  }
  
  .services-list {
    margin-top: 20px;
  }
  
  .service-item {
    border-bottom: 1px solid #ddd;
    padding: 20px 0;
  }
  
  .service-title {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .service-description {
    font-size: 1em;
    color: #ffffff;
  }
  
.itmain {
  color: white;
  /* margin-top: 10.5vh; */
}
.hidden { 
  display: none; 
}

.open { 
  display: block; 
}

.sub-block { 
  display: none; 
}

.block {
  color:white;
  background-color: rgb(73, 66, 66, 0.4);
  margin: 20px;
  /* margin-right: 30px; */
  width: auto;
  /* background-color: rgb(40, 39, 39); */
  border: solid 1px rgb(68, 68, 68);

  padding: 10px;
  border-radius: 5px;
}

.title {
  font-size: 1.2em;
  align-items: center;
  color: var(--text-color);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.sub_content {
  background-color: rgb(73, 66, 66, 0.4);
  margin-top: 10px;
  /* padding: 10px; */
  text-align: left;
  border: solid 1px rgb(68, 68, 68);
  border-radius: 10px;
}

.semester-buttons {
  color: whitesmoke;
  height: 40px;
  cursor: pointer;
  padding: 10px;
  border-radius: 7px;
  border: solid 1px rgb(68, 68, 68);
  background-color: rgb(73, 66, 66, 0.45);
}
.youtube{
  width: 30px;
  height: 30px;
}
.subjects{
  color:white;
  padding: 10px;
  font-size: 20px;
  font-weight: lighter;
  display: flex;
  justify-content: space-between;
  border: #333 solid 1px;
}
.search-bar {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 1em;
}

/* Navbar.css */
.navbar {
    background-color: #333;
    width: 98.5%;
    z-index: 2;
    padding: 10px;
    margin: 10px;
    margin-right: 10px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    position:relative;
  }
  
  /* Styles for the search bar */

.search-bar {
  /* width: 50px; */
  max-width: 400px; /* Maximum width */
  /* margin: 100px; */
  padding: 12px; /* Increased padding for better spacing */
  border: 1px solid #ccc; /* Light border */
  border-radius: 25px; /* More rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Slightly stronger shadow */
  font-size: 16px; /* Font size */
  transition: border 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for border and shadow */
  background-color: #f9f9f9; /* Light background color */
}

.search-bar:focus {
  border: 1px solid #4CAF50; /* Change border color on focus */
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5); /* Shadow effect on focus */
  outline: none; /* Remove outline */
}

@media (max-width: 600px) {
  .search-bar {
    max-width: 50%; /* Responsive maximum width */
    font-size: 14px; /* Smaller font size on smaller screens */
    padding: 10px; /* Adjust padding for smaller screens */
  }
  .navbar{
    width: 100%;
    margin:0; 
  }
}


  .navbar-container {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    padding: 10px;
    width: 100%;
    max-width: 1200px;
    /* padding: 0 10px; */
  }
  
  .navbar-logo {
    font-size: 1.5rem;
    margin-right: 60vw;
    margin-left: 20px;
    text-decoration: none;
    color: #fff;
  }
  
  .navbar-toggle {
    display: none;
    font-size: 1.8rem;
    cursor: pointer;
  }
  
  .navbar-menu {
    display: flex;
    list-style: none;
  }
  
  .navbar-item {
    padding: 0 5px;
    text-decoration: none;
    color: #fff;
    font-size: 1rem;
  }
  
  .navbar-item:hover {
    color: #ddd;
  }
  
  @media (max-width: 808px) {
    .navbar-toggle {
      display: flex;
    }
    .navbar-logo{
      margin-right: auto;
    }
  
    .navbar-menu {
      position: absolute;

      top: 30px;
      right:0;
      width: 40%;
      height: calc(40vh);
      background-color: #333;
      z-index: -2000;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: transform 0.3s ;
      transform: translateY(-100%);
    }
  
    .navbar-menu.active {
      transform: translateY(0);
    }
  
    .navbar-item {
      padding: 10px 0;
      width: 100%;
      text-align: right;
    }
    button{
      background-color: red;
    }
  }
  
@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@400;600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Zain:wght@200;300;400;700;800;900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.headings{
  /* color:white; */
  color: whitesmoke;
  font-size: 6vh;
  padding-left: 7%;
  padding-right: 7%;
  /* display: flex; */
  margin-bottom: 50px;

  text-align: center;
}

.whole-home{
    padding-top: 6.5vh;

}
#wrapper-body {
  height: 100%;
  /* margin-left: 25px */
  width: 100%;
  flex-wrap: wraps;
  display: flex;
  /* justify-content: center; */
}
.container-body {

  margin:10px;
  width: 90%;
  flex-wrap: wrap;
  display: flex;
  row-gap: 20px;
  column-gap: 90px;
  justify-content:center;  /* change here if you wants to change the space between the cards */
}

body::before {
  content: "";
  position: absolute;
  top: 0%;
  right:20%;
  width: 60%;
  height: 70%;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(198, 20, 20, 0.4) 0%, transparent 60%);
  pointer-events: none;
  z-index: -2; /* Ensure it stays behind other content */
}
/* Additional flare effect with animation */
@keyframes flare {
  0% {
    transform: scale(1);
    opacity: 0.4; /* Adjusted opacity for more transparency */
  }
  50% {
    transform: scale(1.1); /* Slightly adjusted scale */
    opacity: 0.6; /* Adjusted opacity for more transparency */
  }
  100% {
    transform: scale(1);
    opacity: 0.4; /* Adjusted opacity for more transparency */
  }
}

.body-flare {
  animation: flare 3s infinite;
}

/* Responsive Styles */
@media (max-width: 768px) {
  body::before {
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
    background: radial-gradient(circle, rgba(198, 20, 20, 0.4) 0%, transparent 70%);
  }
}

@media (max-width: 480px) {
  body::before {
    top: 15%;
    left: 15%;
    width: 70%;
    height: 70%;
    background: radial-gradient(circle, rgba(198, 20, 20, 0.3) 0%, transparent 60%);
  }
}
/* Global styles */
.div-body {
  margin: 0px;
  padding: 0;
  width: 300px;
  border-radius: 0%;
}
.bit{
  color:rgb(182, 51, 51);
}
/* Card styles */
.card-body {
  background-color: black;
  color: white;
  /* opacity: 70%; */
  background-color: rgba(92, 81, 81, 0.4);  ;
  border-radius: var(--border-radius);
  border-style: solid;
  border-width: 1px;
  height: 44vh;
  border-color: rgb(68, 68, 68);
  /* border-color: white; */
  text-align: center;
  padding: 15px;
  width: 360px;
  cursor: pointer;
}
.card-body:hover  {
  border-color: red; /* Change border color to white on hover */
}
/* Illustration styles */
.ilustration-body {
  margin: 30px;
}

.ilustration-body lottie-player {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  overflow: hidden;
  margin: 0 auto;
  /* box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.1),
    -20px -20px 20px rgba(255, 255, 255, 0.1); */
}

/* Heading styles */
.h3-body {
  font-family: var(--primary-font);
  font-size: 2rem;
  line-height: 2.2rem;
  color: var(--text-color); 
  font-weight: bold;
  margin: 30px 0;
}

/* Paragraph styles */
.p-body {
  font-family: var(--primary-font);
  /* margin: 5000px; */
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: var(--text-color); 
  margin:60px auto 10px auto;
  /* margin-bottom: 10px; */
  max-width: 80%;
}

/* Button styles */
.button-body {
  font-family: var(--primary-font);
  font-size: 1.1rem;
  font-weight: bold;
  padding: 15px 80px;
  position:static;
  border-radius: 25px;
  color: var(--text-color);
  border: 1px;
  border-style: solid;
  border-color: rgb(68, 68, 68);
  margin: 30px 0;
  outline: none;
  cursor: pointer;
  background-color:var(--semester-button);
}

/* button:active {
  transform: scale(0.9);
  box-shadow: inset -1px -1px 3px rgba(129, 199, 132, 1),
    -20px -20px 20px rgba(255, 255, 255, 0);
} */
a{
  text-decoration: none;
}
@media (max-width: 768px) {
  .container-body {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
  }}
  @media (max-width: 480px) {
    .container-body {
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }
  
    .headings {
      font-size: 5vh;
      padding-left: 5%;
      padding-right: 5%;
    }
  
    .card-body {
      width: 100%;
      height: auto;
    }
  
    .h3-body {
      font-size: 1.5rem;
      line-height: 1.7rem;
    }
  
    .p-body {
      font-size: 1rem;
      line-height: 1.2rem;
      margin: 30px auto 10px auto;
    }
  
    .button-body {
      font-size: 1rem;
      padding: 10px 60px;
    }
  }